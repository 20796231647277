import { lazy } from "react";
import { Navigate } from "react-router-dom";
import LazyLoad from "./LazyLoad";
import Auth from "./Auth.jsx";

const Home = lazy(() => import("../pages/home/Index"));
const Login = lazy(() => import("../pages/login/Index")); //登录
const Register = lazy(() => import("../pages/register/Index")); //户号管理
const NotFound = lazy(() => import("../pages/notFound/Index")); //404
const Binddevice = lazy(() => import("../pages/binddevice/Index")); //绑定设备
const Waterbill = lazy(() => import("../pages/waterbill/Index")); //用水账单
const Notify = lazy(() => import("../pages/notify/Index")); //系统通知
const Payment = lazy(() => import("../pages/payment/Index")); //缴费记录
const Paymenttwo = lazy(() => import("../pages/paymenttwo/Index")); //缴费记录详细
const Repair = lazy(() => import("../pages/repair/Index")); //报修记录
const Repairtwo = lazy(() => import("../pages/repairtwo/Index")); //报修记录详细
const Inspection = lazy(() => import("../pages/inspection/Index")); //报检报修
const Invoice = lazy(() => import("../pages/inVoice/Index")); //待开发票
const Invoicetwo = lazy(() => import("../pages/inVoicetwo/Index")); //发票申请
const Result = lazy(() => import("../pages/result/Index")); //发票申请结果
const Invoicereview = lazy(() => import("../pages/invoicereview/Index")); //发票审核
const Waterreport = lazy(() => import("../pages/waterreport/Index")); //水质报告

const HomeIndex = lazy(() => import("../pages/home/home/Index")); //首页
const Recharge = lazy(() => import("../pages/home/recharge/Index")); //充值
const Agreement = lazy(() => import("../pages/home/recharge/agreement/Index")); //充值协议
const Waterquality = lazy(() => import("../pages/home/waterquality/Index")); //水质
const Mine = lazy(() => import("../pages/home/mine/Index")); //我的
const Protocol = lazy(() => import("../pages/login/protocol/Index")); //用户协议
const WaterDay = lazy(() => import("../pages/waterbill/day/Index")); //用水账单-日
const WaterDayM = lazy(() => import("../pages/waterbill/day/day-m/Index")); //用水账单-日-近7天
const WaterDayY = lazy(() => import("../pages/waterbill/day/day-y/Index")); //用水账单-日-近30天
const WaterMonth = lazy(() => import("../pages/waterbill/month/Index")); //用水账单-月

const routes = [
  {
    path: "",
    element: <Navigate to="/home"></Navigate>,
  },
  {
    path: "/home",
    element: <LazyLoad component={<Home />} />,
    children: [
      {
        path: "/home",
        element: <Navigate to="/home/home"></Navigate>,
      },
      {
        path: "home",
        element: <LazyLoad component={<HomeIndex />}></LazyLoad>,
      },
      {
        path: "classify",
        element: (
          <LazyLoad
            component={
              <Auth>
                <Recharge />
              </Auth>
            }
          ></LazyLoad>
        ),
      },
      {
        path: "waterquality",
        element: (
          <LazyLoad
            component={
              <Auth>
                <Waterquality />
              </Auth>
            }
          ></LazyLoad>
        ),
      },
      {
        path: "mine",
        element: (
          <LazyLoad
            component={
              <Auth>
                <Mine />
              </Auth>
            }
          ></LazyLoad>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <LazyLoad component={<Login />} />,
  },
  {
    path: "/login/protocol",
    element: <LazyLoad component={<Protocol />} />,
  },
  {
    path: "/home/classify/agreement",
    element: <LazyLoad component={<Agreement />} />,
  },
  {
    path: "/register",
    element: <LazyLoad component={<Register />} />,
  },
  {
    path: "/repair",
    element: <LazyLoad component={<Repair />} />,
  },
  {
    path: "/repairtwo",
    element: <LazyLoad component={<Repairtwo />} />,
  },
  {
    path: "/inspection",
    element: <LazyLoad component={<Inspection />} />,
  },
  {
    path: "/payment",
    element: <LazyLoad component={<Payment />} />,
  },
  {
    path: "/paymenttwo",
    element: <LazyLoad component={<Paymenttwo />} />,
  },
  {
    path: "/waterbill",
    element: <LazyLoad component={<Waterbill />} />,
    children: [
      {
        path: "/waterbill",
        element: <Navigate to="/waterbill/day"></Navigate>,
      },
      {
        path: "day",
        element: <LazyLoad component={<WaterDay />}></LazyLoad>,
        children: [
          {
            path: "/waterbill/day",
            element: <Navigate to="/waterbill/day/day-m"></Navigate>,
          },
          {
            path: "day-m",
            element: <LazyLoad component={<WaterDayM />}></LazyLoad>,
          },
          {
            path: "day-y",
            element: <LazyLoad component={<WaterDayY />}></LazyLoad>,
          },
        ],
      },
      {
        path: "month",
        element: <LazyLoad component={<WaterMonth />}></LazyLoad>,
      },
    ],
  },
  {
    path: "/waterreport",
    element: <LazyLoad component={<Waterreport />} />,
  },
  {
    path: "/notify",
    element: <LazyLoad component={<Notify />} />,
  },
  {
    path: "/binddevice",
    element: <LazyLoad component={<Binddevice />} />,
  },
  {
    path: "/invoice",
    element: <LazyLoad component={<Invoice />} />,
  },
  {
    path: "/invoicereview",
    element: <LazyLoad component={<Invoicereview />} />,
  },
  {
    path: "/invoicetwo",
    element: <LazyLoad component={<Invoicetwo />} />,
  },
  {
    path: "/result",
    element: <LazyLoad component={<Result />} />,
  },
  {
    path: "*",
    element: <LazyLoad component={<NotFound />} />,
  },
];
export default routes;
